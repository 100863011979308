exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-garden-js": () => import("./../../../src/pages/garden.js" /* webpackChunkName: "component---src-pages-garden-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-sound-js": () => import("./../../../src/pages/sound.js" /* webpackChunkName: "component---src-pages-sound-js" */),
  "component---src-templates-garden-post-js": () => import("./../../../src/templates/gardenPost.js" /* webpackChunkName: "component---src-templates-garden-post-js" */),
  "component---src-templates-work-post-js": () => import("./../../../src/templates/workPost.js" /* webpackChunkName: "component---src-templates-work-post-js" */)
}

